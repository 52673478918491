.custom-cell {
    /* border: 2px solid red; */
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 0.8rem;
    line-height: 1.4rem;
    color: rgb(0, 25, 68);
    text-align: left;
    vertical-align: top;
    height: 100% !important;
    display: flex !important;
    align-items: flex-start;
    justify-content: flex-start;
}

.query__metrics__radar__chart__loader{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
}

.query__metrics__chart__label__desc__box{
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 0.8rem;
    line-height: 1.4rem;
    color: rgb(0, 25, 68);
    padding: 5px 10px 10px 10px;
}

.query__metrics__chart__label{
    font-weight: bold;
    font-size: 0.9rem;
}

