.user__statg__main{
    box-sizing: border-box;
    /* border: 2px solid red; */
    font-family: 'Open Sans';
    font-style: normal;
    padding: 1rem 2rem;
    overflow-y: scroll;
}

.user__statg__main::-webkit-scrollbar {
    width: 10px;
}

.user__statg__main::-webkit-scrollbar-thumb {
    background: rgba(52, 51, 51, 0.5);
    border-radius: 10px;
}

.user__statg__main::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 51, 51, 0.7);
}

.user__statg__main__box{
    /* border: 2px solid black; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.user__stat__grap__box{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 10px;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.2);
}

.user_statg__filter__box__main{
    height: 30px;
    display: flex;
}

.user__stat__graph__box__main{
    /* border: 2px solid red; */
    height: calc(75vh - (30px + 1rem));
}

.user__stat__graph__box__main__loader{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-top: 20px;
}

.user__stat__graph__heading{
    font-size: 1.4rem;
    font-weight: 500;
    color: #272727;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user__stat__graph__plot{
    width: 100%;
    height: 80vh;
}

.user__stat__sumup__container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 0px 10px; */
}

.user__stat__sumup__box{
    border: 0.1px solid rgba(0, 0, 0, 0.2);
    padding: 6px 12px;
}

.user__stat__sumup--label{
    font-size: 1.2rem;
    font-weight: bold;
}

.user__stat__sumup--value{
    font-size: 1.2rem;
}