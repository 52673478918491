
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Quicksand:wght@300&display=swap');


body {
    color: black;
    font-family: 'Quicksand', sans-serif;
}

.index{
    font-family: 'Quicksand', sans-serif;
}

.split {
    height: 100vh;
    width: 50%;
    position: fixed;
}

.left {
    /* border: 2px solid red; */
    left: 0;
}

.left>img {
    /* border: 2px solid red; */
    object-fit: cover;
    width:100%; 
    height:100%;
    /* opacity: 0.7; */
}

/* .left> {
    border: 2px solid red;
    left: 0;
    background-color: rgb(240, 240, 245);
    background-image: url("../../assets/KM\ Login\ Page.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.7;
} */

.comp-header{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin: 4rem; */
    /* padding: 1rem; */
}

.company-logo>img{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.8rem;
}

.company-name{
    margin-left: 1rem;
}

.company-name span{
    font-size: 1rem;
    font-weight: bold;
}

.left-content-main{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16rem;
    padding: 2rem;
}

.right-content-main{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.8rem;
    /* margin-top: 2rem; */
    /* padding: 2rem; */
}

.left-content-heading{
    /* border: 2px solid red; */
    /* font-size: 1rem; */
}

.left-content-heading>span{
    font-size: 2.5rem;
    font-weight: bold
}

.left-content-desc{
    /* border: 2px solid blue; */
    font-size: 1rem;
    /* margin-top: 1rem; */
    /* margin-right: 1rem; */
    font-weight: bold;
}

/* New Start */

.comp-header{
    box-sizing: border-box;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin-left: 2rem;
    margin-top: 1rem;
    /* align-items: center; */
    /* margin-top: 2rem; */
    /* margin-right: 50rem; */
    /* padding: 1rem; */
}



/* New End */

.right {
    /* border: 2px solid red; */
    height: 100%;
    right: 0;
    /* border: 2px solid rgb(14, 240, 164); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    row-gap: 3rem;
}

.form{
    /* border: 2px solid black; */
    width: 50%;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.form-heading{
    /* font-size: 1rem; */
    text-align: center;
}

.form-heading>span{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.form-content{
    /* border: 2px solid blue; */
    /* margin-top: 1rem; */
    /* Change */
    /* height: 25rem; */
    display: flex;
    flex-direction: column;
    row-gap: 1.7rem;
}

.form-content-elements{
    /* border: 2px solid red; */
    /* margin-top: 2.8rem; */
}

.form-content-elements>label{
    /* border: 2px solid red; */
    /* margin-top: 6px; */
    font-size: 1.1rem;
    font-weight: bold;
}

.form-content-elements>input[type=email]{
    margin-top: 0.4rem;
    font-size: 1.2rem;
    /* font-family: 'Quicksand', sans-serif; */
    /* font-weight: bold; */
}

.form-content-elements>input[type=password]{
    /* border: 2px solid red; */
    margin-top: 0.4rem;
    font-size: 1.2rem;
    /* margin-bottom: 2rem; */
}

input[type=email] {
    /* border: 2px solid red; */
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    padding-top: 0.5rem;
    padding-left: 0.2rem;
    font-size: 1.5rem;
}

input:focus{
    /* border: none; */
    outline: none;
}

.form-content-elements>.pwd{
    margin-top: 2rem;
}


.pwd-label{
    /* border: 2px solid rebeccapurple; */
    font-size: 2rem;
    margin-top: 20px;
    font-weight: bold;
}

.pwd-input{
    /* border: 2px solid red; */
    display: block;
    width: 100%;
    height:2rem;
    font-size: 2rem;
    padding-left: 0.2rem;
    /* padding-top: 2rem; */
    border: none;
    /* margin-top: 20rem; */
    border-bottom: 1px solid black;
    /* padding: 9rem; */
    font-size: 2rem;
}

.errorMessage{
    color: red;
}

::placeholder{
    font-size: 1.5rem;
    padding: 0;
}

.btn{
    /* border: 2px solid red; */
    display: block;
    width: 100%;
    height: 2rem;
    /* margin-top: 3rem; */
    font-size: 1.2rem;
    padding: 1.5rem;
    background-color: #F37D24;
    /* border: 1px solid #F37D24; */
    border: none;
    color: white;
    border-radius: 0.6rem;
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn:hover{
    background-color: #eb873b;
    border: none;
}

.index-parent{
    display: flex;
    justify-content: center;
}

.login-footer{
    /* position: fixed; */
    /* height: 6vh;
    margin-top: 94vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-left: 50%;
    border-top: 1px solid black;
    z-index: 1; */
    /* font-weight: bold; */
    /* background-color: white; */
    /* border: 2px solid red; */
    /* height: 100vh; */
    /* background-color: black; */
    /* background-color: azure; */
    padding-bottom: 0.8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding-bottom: 1rem;
}

.horizontalLine{
    /* border: 0.1px solid rgb(28, 28, 28); */
    border: 1px solid rgb(26 25 25 / 58%);
    width: 90%;
    margin: auto;
}

.login-footer>span{
    font-weight: bold;
    font-size: 1rem;
}

.login-footer>span>a, .login-footer>span, .login-footer{
    font-size: 1rem;
    color: black;
    font-weight: bold;
    /* text-decoration: underline; */
}