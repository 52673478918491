.admin__sidebar__main {
    /* border: 2px solid red; */
    /* height: calc(100% - (39px + 2rem)); */
    height: 100%;
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
}

.admin__sidebar__main__box {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    /* padding: 1rem; */
}

.admin__sidebar__box {
    width: 100%;
    /* border-right: 1.2px solid #b8b4b4; */
    border-right: 1.2px solid rgba(0, 0, 0, 0.12);
    background: #FFFFFF;
    /* background: #F9F9F9; */
    padding: 14px 4px 4px 6px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom-left-radius: 8px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.a__sidebar__heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.a__sidebar__data {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding-left: 8px;
}

.a__sidebar__line {
    border: 0.1px solid rgba(0, 0, 0, 0.2);
    /* border: 0.2px solid #b8b4b4; */
}

.a__sidebar__content__box {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.a__sidebar__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px 8px 10px;
    gap: 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    /* color: #5095FD; */

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    opacity: 0.9;
}


.a__sidebar__content:hover {
    background: rgba(80, 149, 253, 0.2);
}



.a__sidebar__content--active {
    border-left: 6px solid #5095FD;
    background: rgba(80, 149, 253, 0.2);
}

.a__sidebar__mtr__img {
    width: 25px;
    height: auto;
}

.a__sidebar__content__elem1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.a__sidebar__content__elem2{
    display: flex;
    align-items: center;
    justify-content: center;
}

.a__sidebar__dropdown__box__main{
    /* border: 2px solid #5095FD; */
    border: 0.1px solid rgba(0, 0, 0, 0.2);
    height: 250px;
}

.a__sidebar__dropdown__box__main__new{
    border: 0.1px solid rgba(0, 0, 0, 0.2);
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 4px 8px 4px 8px;
    box-sizing: border-box;
}

.a__sidebar__loader__box{
    display: flex;
    align-items: center;
    justify-content: center;
}


.a__sidebar__dropdown__box{
    height: 100%;
    padding: 4px 4px 8px 10px;
    color: #272727;
    opacity: 0.9;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
}



.a__sidebar__dropdown__box--category{
    height: 100%;
    padding: 4px 6px 8px 6px;
    color: #272727;
    opacity: 0.9;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.a__sidebar__dropdown__data__box{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 0.1px solid rgba(0, 0, 0, 0.2);
    max-height: 80%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    opacity: 0.9;
}

.a__sidebar__subcategory__heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 6px 6px;
}

.a__sidebar__subcategory__heading--active{
    background: rgba(80, 149, 253, 0.2);
}

.a__sidebar__subcategory__content{
    flex: 1;
    /* height: 80%; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 6px 6px;
    cursor: pointer;
    overflow-y: auto;
}

.a__sidebar__subcategory__content--data{
    padding: 4px 4px;
}

.a__sidebar__subcategory__content--dataActive{
    background: rgba(80, 149, 253, 0.2);
}

.a__sidebar__subcategory__content--data:hover{
    background: rgba(80, 149, 253, 0.2);
}

.a__sidebar__subcategory__content--loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.a__sidebar__subcategory__content::-webkit-scrollbar {
    width: 7px;
}

.a__sidebar__subcategory__content::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.25);
    border-radius: 5px;
}









.a__sidebar__dropdown__content__box{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    overflow-y: scroll;
}

.a__sidebar__dropdown__content__box::-webkit-scrollbar {
    width: 8px;
}

.a__sidebar__dropdown__content__box::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.25);
    border-radius: 5px;
}

.a__sidebar__dropdown__content{
    box-sizing: border-box;
    /* background-color: rgba(80, 149, 253, 0.2); */
    width: 100%;
    padding: 4px 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    opacity: 0.9;
}

.a__sidebar__dropdown__content--active{
    background-color: rgba(80, 149, 253, 0.2);
}

.a__sidebar__dropdown__content:hover{
    background-color: rgba(80, 149, 253, 0.2);
}