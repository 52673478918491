.technicaladvisor__page {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    /* background: var(--body__background); */
    background-image: url("../image/technicaladvisor-page/bg-page-no-sidebar.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-size: cover; */
    padding: 1rem;
    font-family: 'Work Sans';
}

/* Only to remove padding */
.technicaladvisor__page--activeNavbar {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-image: url("../image/technicaladvisor-page/bg-page-sidebar.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-family: 'Work Sans';
}



.technicaladvisor__page__main__box {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    font-family: 'Work Sans';
}


.technicaladvisor__page__main__box--activeNavbar {
    position: absolute;
    box-sizing: border-box;
    left: 260px;
    width: calc(100% - 260px);
    height: 100%;
    padding: 1rem 1rem 1rem 0rem;
    font-family: 'Work Sans';
}

.technical__advisor {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    font-family: 'Work Sans';
    background: #EEF2FE;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
}