.table__popup__main {
    box-sizing: border-box;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(0, 0, 0, 0.6);
    /* border: 2px solid red; */
    padding: 1rem;
}

.table__content {
    /* border: 1.2px solid rgb(207, 203, 203); */
    border-radius: 18px;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.table__popup__close__box {
    display: flex;
    flex-direction: row-reverse;

    /* border: 1.2px solid rgba(0, 0, 0, 0.12); */
    /* border: 1.2px solid rgba(0, 0, 0); */
    align-items: center;
}

.table__popup__close{
    border: 1.2px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    cursor: pointer;
    padding: 2px 2px;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.table__content::-webkit-scrollbar {
    width: 8px;
}

.table__content::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.5);
    border-radius: 5px;
}