.admin__content__main {
    box-sizing: border-box;
    /* border: 2px solid red; */
    font-family: 'Open Sans';
    font-style: normal;
    padding: 1rem 2rem;
}


.admin__content__container {
    /* border: 2px solid black; */
    width: 100%;
    height: 100%;
}

.real__time__metrics__elem--gaph{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.userinqueries__filter__box--changes {
    gap: 20px !important;
}

.filter__box__content--changes {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 3 !important;
}

.real__time__metrics__heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
}

.e__matrix__expand {
    align-items: center;
    background-color: #fff;
    border: 1.2px solid #0000001f;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 3px 2px;
}

.real__time__metrics__table__box{
    border: 1.2px solid #0000001f;
    height: calc(100% - 30px);
    padding-right: 10px;
    overflow: auto;
}


.real__time__metrics__table__box::-webkit-scrollbar {
    width: 10px;
}

.real__time__metrics__table__box::-webkit-scrollbar-thumb {
    background: rgba(52, 51, 51, 0.5);
    border-radius: 10px;
}

.real__time__metrics__table__box::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 51, 51, 0.7);
}