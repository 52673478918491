.admin__dashboard__main__page{
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background: var(--body__background);
    padding: 1rem;
}

.admin__dashboard__box{
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background: var(--adminpage__background);
    display: flex;
    flex-direction: column;
}

.admin__dashboard__elems{
    height: calc(100% - (39px + 2rem));
    width: 100%;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
}

.admin__dashboard__elems > :first-child{
    width: 250px;
    height: 100%;
    /* border: 2px solid red; */
}

.admin__dashboard__elems > :nth-child(2){
    width: calc(100% - (250px));
    height: 100%;
    /* border: 2px solid black; */
}