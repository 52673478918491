.query__metrics__main {
    /* border: 2px solid red; */
    box-sizing: border-box;
    font-family: 'Open Sans';
    font-style: normal;
    padding: 10px 0px 10px 10px;
}

.query__metrics__box {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 15px;
    gap: 30px;
    box-sizing: border-box;
}

.query__metrics__box::-webkit-scrollbar {
    width: 10px;
}

.query__metrics__box::-webkit-scrollbar-thumb {
    background: rgba(52, 51, 51, 0.5);
    border-radius: 10px;
}

.query__metrics__box::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 51, 51, 0.7);
}



.query__metrics__chart {
    border: 1.2px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.query__metrics__tabel__box{
    /* border: 1.2px solid rgba(0, 0, 0, 0.2); */
    height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.query__metrics__tabel__expand__box{
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    align-items: center;
}

.query__metrics__tabel__expand{
    border: 1.2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 2px;
}

.query__metrics__table {
    border: 1.2px solid rgba(0, 0, 0, 0.2);
}




.query__metrics__list__box__main {
    /* border: 2px solid blue; */
    height: 40vh;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8vh;
}



.e__matrix__table__row {
    /* font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #272727;
    opacity: 0.9; */
    text-align: left;
    vertical-align: top;
}