

.technicaladvisor__main__box {
    height: 85vh;
    /* height: calc(100% - 39px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    /* border: 2px solid red; */
}

@media screen and (min-height: 900px) {
    .technicaladvisor__main__box {
        height: 88.5vh;
    }
}

@media screen and (min-height: 920px) {
    .technicaladvisor__main__box {
        height: 89vh;
    }
}

@media screen and (min-height: 940px) {
    .technicaladvisor__main__box {
        height: 90vh;
    }
}

@media screen and (min-height: 990px) {
    .technicaladvisor__main__box {
        height: 91vh;
    }
}


.technicaladvisor__upper {
    /* border: 2px solid black; */
    height: 83%;
    padding-bottom: 15px;
    box-sizing: border-box
}

.technicaladvisor__bottom {
    height: 17%;
    /* border: 2px solid blue; */
}

.technicaladvisor__initial {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.technicaladvisor__top {
    /* border: 2px solid black; */
    width: 100%;
    margin-top: 2.5rem;
}

.technicaladvisor__intro {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.2rem;
}

/* Message Height */
@media screen and (min-height: 900px) {
    .technicaladvisor__initial {
        row-gap: 8rem;
    }

    .technicaladvisor__intro {
        row-gap: 2.5rem;
    }
}



.technicaladvisor__intro img {
    width: 3rem;
    border-radius: 2rem;
    box-shadow: 0px 9px 30px rgba(5, 113, 255, 0.3);

}

.technicaladvisor__intro span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #0E0E0F;
}




.technicaladvisor__boxes {
    padding: 0rem 30%;
}

@media screen and (max-width: 1250px) {
    .technicaladvisor__boxes {
        padding: 0rem 25%;
    }
}

@media screen and (max-width: 1050px) {
    .technicaladvisor__boxes {
        padding: 0rem 22%;
    }
}

@media screen and (max-width: 985px) {
    .technicaladvisor__boxes {
        padding: 0rem 20%;
    }
}

@media screen and (max-width: 985px) {
    .technicaladvisor__boxes {
        padding: 0rem 16%;
    }
}

@media screen and (max-width: 950px) {
    .technicaladvisor__boxes {
        padding: 0rem 14%;
    }
}

@media screen and (max-width: 900px) {
    .technicaladvisor__boxes {
        padding: 0rem 10%;
    }
}

@media screen and (max-width: 765px) {
    .technicaladvisor__boxes {
        padding: 0rem 6%;
    }
}

@media screen and (max-width: 720px) {
    .technicaladvisor__boxes {
        padding: 0rem 2%;
    }
}

.technicaladvisor__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.8rem;
    padding: 0.8rem;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.technicaladvisor__box__toplines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.4rem;
}

.technicaladvisor__box__toplines>img {
    width: 2rem;
}

.technicaladvisor__box__toplines>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 28px;
    text-align: center;
    color: #0066B2;
}

.technicaladvisor__quesBox {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
}

.technicaladvisor__quesBox button {
    width: 100%;
    cursor: pointer;
    background: transparent;
    outline: none;
    text-align: left;

    background: #F6F9FB;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    backface-visibility: hidden;
}


.technicaladvisor__quesBox button:hover {
    background: #EEF2FE;
}

.technicaladvisor__quesBox>button>span {
    display: inline-block;
    text-align: left;
    padding: 8px 12px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #0E0E0F;
}


.technicaladvisor__bottom {
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.technicaladvisor__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    padding: 0.7rem;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* border: 2px solid red; */
}

.technicaladvisor__footer__valiance{
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #0E0E0F;
}

.technicaladvisor__footer--name{
    color: blue;
    /* opacity: 0.5; */
}

.technicaladvisor__footer textarea {
    width: 90%;
    background: transparent;
    padding-top: 0.6rem;
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    cursor: text;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #0E0E0F;
}

.technicaladvisor__footer textarea::placeholder {
    font-size: 1rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
}

.technicaladvisor__footer button {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}



.technicaladvisor__message__main {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.technicaladvisor__message__main>div {
    padding: 1.5rem 1.8rem 0rem 1.8rem;
}

.technicaladvisor__message__main::-webkit-scrollbar {
    width: 8px;
}

.technicaladvisor__message__main::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.25);
    border-radius: 4px;
}

.technicaladvisor__message__boxes {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.technicaladvisor__bot__box {
    display: flex;
    column-gap: 1rem;
    flex-direction: row;
    align-items: flex-start;
}

.technicaladvisor__bot__user__img {
    transition: 1s ease;

}

.technicaladvisor__bot__user__img:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
}


.technicaladvisor__bot__message {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0px 12px 12px 12px;
    padding: 0.8rem;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 1.4rem;
}

.technicaladvisor__bot__message>span {
    white-space: pre-line;
    word-wrap: break-word;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.8rem;
    color: #0E0E0F;
}

.technicaladvisor__links__box {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.technicaladvisor__links__box>span {
    font-size: 1.1rem;
    color: #0E0E0F;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
}

.technicaladvisor__links {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    width: 100%;
}

.technicaladvisor__links li {
    list-style-type: none;
}


.technicaladvisor__links a span {
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.7rem;
    word-wrap: break-word;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    color: #2F80ED;
}

.technicaladvisor__messages__bottom {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    color: #828282;
    row-gap: 0.8rem;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 1.3rem;
}

.technicaladvisor__messages__bottom__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.6rem;
}

.technicaladvisor__messages__bottom__buttons--top {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.7rem;
}

.copy__clipboard {
    background: #f2f2f2 !important;
    border: 1px solid #8b8888 !important;
}

.technicaladvisor__messages__bottom__buttons--top button {
    display: flex;
    padding: 0.5rem;
    background: transparent;
    outline: none;
    border: 1px solid #E0E0E0;
    border-radius: 24px;
    cursor: pointer;
}

.technicaladvisor__messages__bottom__buttons--top>button:hover {
    border: 1px solid #aba8a8;
    background: #f7f4f4;
}

.like__dislike__buttons {
    display: flex;
    flex-direction: row;
    column-gap: 0.8rem;
}


.like__dislike__buttons>button:hover {
    border: 1px solid #aba8a8;
    background: #f7f4f4;
}

.technicaladvisor__messages__audio__box {
    border-radius: 24px;
    padding: 8px 12px 8px 8px;
}

.technicaladvisor__messages__audio__box:hover {
    border: 1px solid #aba8a8;
    background: #f7f4f4;
}

.technicaladvisor__messages__audio__box>button {
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    cursor: pointer;
}

.technicaladvisor__messages__audio__box>button>span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: #828282;
}






.technicaladvisor__user__box {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 1rem;
    align-items: flex-start;
}

.technicaladvisor__user__box>img {
    width: 2.5rem;
}

.technicaladvisor__user__message {
    background: #2AA9E2;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px 0px 12px 12px;
    padding: 0.8rem;
    max-width: 60%;
}

.technicaladvisor__user__message>span {
    color: #FFFFFF;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
}

.response__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
}