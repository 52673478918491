/* New Font Family */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


.sidebar__main__box {
    width: 260px;
    height: 99vh;
    display: flex;
    justify-content: center;
    position: fixed;
    color: #FFFFFF;
}

.sidebar__boxes {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
}

.sidebar__top {
    box-sizing: border-box;
    width: inherit;
    padding-left: 0.9rem;
    padding-right: 0.8rem;
    font-family: 'Open Sans';
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.sidebar__newChat button {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.8rem;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.64);
    border-radius: 30px;
}

.sidebar__newChat button span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 18px;
    color: #0066B2;
}

.sidebar__sector {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
}

.sidebar__sector__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
}

.sidebar__sector__content span {
    font-size: 0.8rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF;
}

.sidebar__sector__dropdown {
    position: relative;
}

.sidebar__sector__dropdown--Nonedisplay {
    display: none;
}


.sidebar__sector__dropdown-content {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    top: 3.5rem;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0.6rem 0rem;
}


.horizontal__line2 {
    border: 0.1px solid #828282;
}

.sidebar__sector__dropdown-content a {
    text-decoration: none;
    color: #828282;
    border-radius: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    row-gap: 10rem;
    padding: 0.4rem 0.5rem 0.4rem 1rem;
}

.sidebar__sector__dropdown-content>a>span {
    text-decoration: none;
    width: 85%;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4E4E51;
}

.sidebar__sector__dropdown-content--active {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4E4E51;
}

.sidebar__sector__dropdown-content a:hover {
    background: #EEF2FE;
}



.sidebar__sector__dropdown button {
    background: transparent;
    outline: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.64);
    border-radius: 30px;
}

.sidebar__sector__dropdown button span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}



.horizontal__line {
    border: 1px solid rgba(255, 255, 255, 0.64);
    margin-top: 0.5rem;
}



.sidebar__bottom {
    height: 72%;
}

.sidebar__chat__boxes {
    height: 100%;
    overflow-y: scroll;
}

@media screen and (min-height: 900px) {
    .sidebar__chat__boxes {
        height: 110%;
    }
}

@media screen and (min-height: 980px) {
    .sidebar__chat__boxes {
        height: 113%;
    }
}

.sidebar__loading__spinner {
    margin-top: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sidebar__chat__boxes::-webkit-scrollbar {
    width: 7px;
}

.sidebar__chat__boxes::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
}



.sidebar__chat__boxes {
    padding-left: 0.9rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
}



.sidebar__chat__box>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.64);
}

.sidebar__chat__box button {
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.8rem;
    width: 100%;
    cursor: pointer;
    padding: 12px 10px 12px 8px;
    border-radius: 0.4rem;
}


.sidebar__chat__box--activebutton {
    background: rgba(255, 255, 255, 0.08) !important;
}


.edit__delete__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    height: 90%;
}

.edit__delete__btns--tag {
    height: 100%;
    /* border: 2px solid red; */
}

.edit__delete__btns--tag>img {
    width: 95%;
}

.edit__button__icon>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
}


 .edit__button__icon {
    position: relative;
    z-index: 1;
  }
  
  .edit__button__icon .tooltip {
    position: absolute;
    z-index: 2;
    top: -47px; 
    left: 50%;
    transform: translateX(-50%);
    background: #0E0E0F;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .edit__button__icon:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .edit__button__icon .tooltip::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border: 8px solid transparent;
    border-top-color: #0E0E0F;
  } 




.sidebar__chat__box button:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.sidebar__chat__title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.sidebar__chat__title--active {
    width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.sidebar__chat__title--active--editting {
    box-sizing: border-box;
    cursor: text;
    width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}


.titleInput {
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    outline: none;
    border: none;
    overflow: hidden;
    padding: 0.1rem;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}