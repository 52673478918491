.sector__page__main__box {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    background-image: url("../image/technicaladvisor-page/bg-page-no-sidebar.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.sector__page__content__box{
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background: #EEF2FE;
}