@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.userinqueries__main {
    /* border: 2px solid red; */
    /* remaining height = page_height - (navbar_height + padding) */
    height: calc(100% - (39px + 2rem));
    box-sizing: border-box;
}

.userinqueries__main--box {
    /* border: 2px solid black; */
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans';
    font-style: normal;
    row-gap: 5vh;
}

/* Element-1 */
.userinqueries__userdetail__box {
    /* border: 2px solid blue; */
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1vw;
    height: 9vh;
    font-size: 1rem;
}


.userinqueries__userdetail__element1 {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    column-gap: 2vw;
}

.userinqueries__userdetails {
    /* border: 2px solid black; */
    display: flex;
    column-gap: 2vw;
}

.userinqueries__userdetails--elem {
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5vw;
    font-family: 'Open Sans';
    font-style: normal;
    color: #828282;
}

.userinqueries__username {
    /* border: 2px solid rgb(157, 13, 201); */
    word-break: break-all;
    color: #001944;
    /* font-weight: bold; */
}

.userinqueries__userid {
    /* border: 2px solid red; */
    word-break: break-all;
    color: #001944;
    /* font-weight: bold; */
}



.userinqueries__userdetail__element2 {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1vw;
    height: 100%;
}

.userinqueries__userdetail__element2>span {
    /* font-weight: bold; */
}

.userinqueries__view__questions {
    /* width: 2.5vw; */
    /* height: 2.5vh; */
    height: 6vh;
    border: none;
    background: transparent;
    cursor: pointer;
}

.userinqueries__view__questions>img {
    height: 100%;
}



/* Element-3 */
.userinqueries__userchat__box {
    /* border: 2px solid black; */
    /* 100% - (userDetailBoxHeight + row_gaph + filterHeight + rowGaph) */
    height: calc(100% - (9vh + 5vh + 5vh + 5vh));
    width: 100%;

    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
}

.userinqueries__userchat__box--main {
    height: 100%;
    width: 100%;
}

.userinqueries__userchat__heading {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    /* padding: 0vh 2.5vw; */

    padding-left: 30px;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    color: #333333;
    height: 15%;
}

.userinqueries__userchat__heading {
    height: 15%;
}

.userinqueries__userchat__chat__box {
    height: 85%;
}



/* Table-Heading Width */
.userinqueries__userchat__elem1 {
    /* border: 2px solid black; */
    width: 5%;
}

.userinqueries__userchat__q__elem2 {
    /* border: 2px solid black; */
    width: 82%;
}

.userinqueries__userchat__q__elem3 {
    /* border: 2px solid black; */
    width: 10%;
}

/* Table-Content Width */
.user_id--color {
    /* border: 2px solid black; */
    width: 5%;
}

.userinqueries__userchat__elem2 {
    /* border: 2px solid black; */
    width: 82%;
}

.userinqueries__userchat__elem3 {
    /* border: 2px solid black; */
    width: 10%;
}

.userinqueries__userchat__elem4 {
    /* border: 2px solid rgb(126, 68, 68); */
    width: 3%;
}



.userinqueries__userchat__chat__box {
    /* border: 2px solid red; */
    /* height: 85%; */
    width: 100%;
}

.userinqueries__userchat__chat__box--scroll {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* padding-left: 2.5vw; */
}

.userinqueries__userchat__chat__box--scroll::-webkit-scrollbar {
    width: 0.4rem;
}

.userinqueries__userchat__chat__box--scroll::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.20);
    border-radius: 4px;
}

.userinqueries__userchat__chats {
    /* border: 2px solid black; */
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    gap: 50px;

    padding-left: 30px;
    padding-top: 0.9rem;
    padding-bottom: 1rem;
    font-size: 0.9rem;
    box-sizing: border-box;
}

.user_id--color {
    color: #828282;
    font-size: 0.9rem;
    /* border: 2px solid red; */

    word-break: break-all;
}

.userinqueries__userchat__elem2 {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userinqueries__userchat__elem2--question {
    /* border: 2px solid black; */
    /* width: 100%; */
    width: 100%;
    color: #001944;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.userinqueries__userchat__elem3 {
    /* border: 2px solid black; */
    font-size: 0.9rem;
}

.userinqueries__userchat__elem4 {
    /* border: 2px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.expand__chat__btn {
    /* border: 2px solid black; */
    /* width: 2.5%; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.expand__chat__btn>img {
    width: 100%;
}

.userinqueries__userchat__elem2--question>span {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #333333;
    font-weight: bold;
}

.userinqueries__userchat__ques--time {
    color: #828282;
}

.userinqueries__userchat__ques {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    font-size: 0.9rem;
    line-height: 1.5rem;
}


/* Box Expand CSS -----------------*/
.userinqueries__userchat__elem2__expandedQuestion {
    /* border: 2px solid red; */
    width: calc(90% - 2.5rem);
    display: flex;
    flex-direction: column;
    row-gap: 0.9rem;
}

.userinqueries__userchat__elem2__expandedQuestion__main {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    max-height: 50vh;
    overflow-y: auto;
    padding: 1rem;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.userinqueries__userchat__elem2__expandedQuestion__main::-webkit-scrollbar {
    width: 0.4rem;
}

.userinqueries__userchat__elem2__expandedQuestion__main::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.4);
    border-radius: 4px;
}

.userinqueries__question {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}



.userinqueries--ques {
    line-height: 1.5rem;
    color: #001944;
}

.userinqueries__answer {
    color: #828282;
    line-height: 1.5rem;
}










/* Element-3 */
.userinqueries__filter__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid black; */
    height: 5vh;
}

.userinqueries__filter__box__leftelem {
    height: 100%;
    display: flex;
    column-gap: 2.5vw;

    /* position: relative; */
}

.userinquires__filter {
    height: 100%;
}

.userinqueries__leftelem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 6px 8px 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.userinquires__filter__button {
    height: 100%;
    display: flex;
    /* border: 2px solid red !important; */
    flex-direction: row;
    align-items: center;
    column-gap: 0.8vw;
    border: none;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0px 1vw;
    cursor: pointer;
    position: relative;
}

.userinquires__filter__badge {
    position: relative;
    display: inline-block;
    color: #828282;
    font-size: 0.9rem;
    font-family: 'Open Sans';
    font-style: normal;
}

.userinquires__filter__badge .badge {
    position: absolute;
    top: -12px;
    right: -20px;
    /* padding: 5px 10px; */
    padding: 5px 5px;
    border-radius: 50%;
    background-color: #F47C42;
}

.filter--color {
    color: #333333;
}

.userinqueries__filter__number__box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.userinqueries__filter__left__elem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.userinqueries__filter__number {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.userinqueries__number__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.userinqueries__showing--color {
    color: #333333;
    font-size: 0.9rem;
}

.userinqueries--count {
    font-weight: bold;
    font-size: 0.9rem;
}

.filter__box__dropdown {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 1.2rem 1rem;
    margin-top: 0.7rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    position: absolute;
}



.filter__box__dropdown__elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    cursor: pointer;
    padding: 0.2rem 0.6rem;

    position: relative;
}


.filter__box__dropdown__elem:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #fdfdfd;
}

.filter__box__dropdown__elem__span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* text-align: center; */
    color: #333333;
}

.filter__box__content1 {
    position: absolute;
    left: 550px;
    top: 260px;
}

.filter__date__box {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    /* padding: 1.5rem 0.6rem; */
    padding: 1rem 0.8rem 1.5rem 0.7rem;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.filter__date__elem1 {
    display: flex;
    flex-direction: column;
    gap: 0rem !important;
}

.filter__date__child__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8rem;
    /* padding: 6px 8px; */
    padding: 6px 8px 6px 0px;
    cursor: pointer;
}

.filter__date__child__box:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #fdfdfd;
}

.filter__date__child__box1--color {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #fdfdfd;
}


.filter__date__font__heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
    margin-bottom: 0.5rem;
}

.filter__date__font {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
}

.filter__date__horizontal__line {
    border: 1px solid #E0E0E0;
}

.filter__date__elem2 {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.filter__date__elem2__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
}

.filter__date__specific__date {
    background: #FFFFFF;
    /* Gray/300 */
    /* border: 1px solid #D0D5DD; */
    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 4px 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter__date__specific__date__elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.filter__date__specific__date__elem__span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.filter__date__button__box {
    display: flex;
}


.filter__chat__box {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    padding: 1rem 0.6rem;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.filter__chat__span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
}

.filter__chat__input__box {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    padding: 5px 6px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter__chat__input {
    border: none;
    outline: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    /* border: 2px solid red; */
}

.filter__chat__input::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
}


.sortBy__filter__box {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    position: absolute;
    /* right: 2px; */
    /* left: 420px; */
    padding: 14px 10px;
    margin-top: 3rem;
}

.sortBy__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
    width: 100%;
    padding: 10px 10vw 8px 10px;
}

.sortBy__text--active {
    color: blue;
}

.sortBy__text:hover {
    color: blue;
    background-color: #f6f3fd;
}

.antd__datepicker input::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.userinqueries__number__box__elem1 {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.userinquiries__chat__date__time__loader__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.userinquiries__chat__date__time__loader__box>*:first-child {
    width: 8%;
}

.userinquiries__chat__date__time__loader__box>:nth-child(2){
    width: 5%;
}