
.sector__main__box {
    font-family: 'Work Sans';
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
}

@media screen and (min-height: 900px) {
    .sector__main__box {
        gap: 6.5rem;
    }
}

.sector__top {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

@media screen and (min-height: 900px) {
    .sector__top {
        margin-top: 5rem;
        row-gap: 2rem;
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-18px);
    }

    100% {
        transform: translatey(0px);
    }
}

.sector__welcome__msg {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}

.sector__welcome__msg>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    color: #0E0E0F;
}

.sector__top>span {
    text-align: center;
    max-width: 90%;
    margin: auto;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.7rem;
    color: #2D3443;
}

.sector__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.5rem;
}

.sector__boxes {
    width: 50%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 5.5rem;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    justify-content: center;
}

@media screen and (min-height: 900px) {
    .sector__boxes {
        row-gap: 1.8rem;
        column-gap: 1.5rem;
    }
}

.sector__boxes span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    color: #0E0E0F;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.sector__box {
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.sector__box:hover {
    transform: rotate3d(0.4, 0.466, 0, 0deg) rotate(1deg);
}



.sector__box--activeColor {
    border: 1.6px solid #0066B2 !important;
}

.sector__box button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0rem 0.6rem 0rem 0.8rem;
}






.sector__box__leftContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.6rem;
}

.sector__box__leftContent--image {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4.5px;
}

.sector__box__leftContent--image>img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
}

.sector__box__leftContent>span {
    max-width: 68%;
    text-align: left;
}





.sector__page__continue>button {
    width: 100%;
    height: 100%;
    padding: 0.8rem 1.5rem;
    gap: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;

    background: #0066B2;
    border: 1px solid #FFFFFF;
    border-radius: 30px;
}

.sector__page__continue>button:hover {
    transform: rotate3d(0.4, 0.466, 0, 0deg) rotate(-1deg);
}

.sector__page__continue>button>span {

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 26px;
    color: #FFFFFF;
}



.sector__box__continueBtn--orange {
    background: #F47920 !important;
}

.sector__box--continueBtn--White {
    color: #FFFFFF !important;
}

.page__fixed__footer__bottom {
    position: fixed;
    left: 0;
    bottom: 1.5rem;
    width: 100%;
    text-align: center;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #0E0E0F;
}