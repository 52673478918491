.feedback__page__main {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.feedback__page__box {
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.5);

}

.feedback__main__box {
    position: absolute;
    top: 25%;
    height: 50%;
    left: 20%;
    width: 60%;
}

.likeresponse__main__page {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
}

.likeresponse__upper__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 10rem;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
}

.likeresponse__Intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
}

.likeresponse__upper__box>img {
    cursor: pointer;
}

.likeresponse__Intro>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #0E0E0F;
}


.likeresponse__lower__box {
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    background: linear-gradient(293.59deg, #E5F3FD -25.19%, #F9FBFB 69.6%);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    padding: 2rem 1.5rem;
}

.likerespnse__heading__msg {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #2D3443;
}

.likeresponse__textarea {
    box-sizing: border-box;
    width: 100%;
    height: 10rem;
}

.likeresponse__textarea>textarea {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    cursor: text;
    padding: 0.6rem;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #2D3443;
}

.likeresponse__textarea>textarea::placeholder {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #828282;
    letter-spacing: 1px;
}

.likeresponse__lowest__box {
    background: #E0F3FB;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 1rem 1.5rem 1rem 0rem;
}

.likeresponse__submit__btn__box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;
}

.likeresponse__submit__feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    border: none;
    padding: 8px 30px;
    cursor: pointer;
    background: #0066B2;
    border-radius: 30px;
}

.likeresponse__submit__feedback:hover {
    background: #0c90f1;
}

.likeresponse__submit__btn__box>button>span {
    color: #FFFFFF;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.7rem;
}

.likeresponse__cancel__feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    border: none;
    padding: 8px 30px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.likeresponse__cancel__feedback:hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: #f6f6f6;
}

.likeresponse__cancel__feedback>span {
    color: #0066B2 !important;
}







.feedback__main__page--responseSuccess {
    position: absolute;
    top: 25%;
    left: 30%;
    max-width: 35%;
}

.likeresponse__success__msg__box {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    padding: 3rem 0.8rem;
}

.likeresponse__success__msg__closingImg {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0.5rem;
}

.likeresponse__success__msg__closingImg>img {
    padding-right: 2rem;
    width: 1.5rem;
    cursor: pointer;
}

.likeresponse__success__msg__horizontal__line {
    width: 100%;
}


.likeresponse__success__msg__box>img {
    width: 4rem;
    margin-bottom: 1rem;
}

.likeresponse__success__msg__box>span {
    text-align: center;
    color: #333333;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
}