.users__statistics__main {
    /* border: 2px solid red; */
    /* remaining height = page_height - (navbar_height + padding) */
    /* height: calc(100% - (39px + 2rem)); */
    height: 100%;
}

.users__statistics__main--box {
    /* border: 2px solid black; */
    box-sizing: border-box;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    padding: 1rem 2rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-family: 'Open Sans';
    font-style: normal;
}

.user__statistics__info__box__main {
    /* flex: 1; */
    height: calc(100% - (2rem + 30px));
    /* border: 2px solid violet; */
}

.user__statistics__info__box {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    gap: 2rem;
}

.userstatistics__filter__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid black; */
    height: 30px;
}


/* Element-1 */
.user__statistics__countvalues {
    /* border: 2px solid red; */
    width: 20%;
    height: 100%;
}

/* Element-2 */
.user__statistics__userslist {
    /* border: 2px solid violet; */
    width: 100%;
    height: 100%;
}



/* Element-1 */
.user__statistics__countvalues {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: space-between;
}

.user__statistics__countelem1{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.user__statistics__countvalues__box {
    /* border: 2px solid black; */
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    background: #FFFFFF;
    padding: 1.2rem 1rem;
    font-family: 'Open Sans';
    font-style: normal;
}

.user__statistics__countelem2{
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.user__statistics__matrix{
    width: 100%;
    cursor: pointer;
    /* border: 2px solid rgb(32, 57, 222); */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 4px;
    background-color: #5095FD;
    /* background-color: #183364; */
    border-radius: 8px;
    color: white;
    font-size: 1rem;
}

.user__statistics__val {
    color: #00B4D8 !important;
    font-size: 2.1rem !important;
}

.user__statistics__countvalues__box span:nth-child(2) {
    color: #828282;
    font-size: 0.9rem;
}

/* Element-2 */

.heading__disabled__btn {
    display: none;
    cursor: default;
}

.heading__disabled__img {
    display: none;
}

.user__statistics__search__bar__box {
    box-sizing: border-box;
    width: 100%;
    /* font-size: 16px; */
    /* padding: 12px 20px 12px 40px; */
    margin-bottom: 1rem;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid #ddd;
    /* background-color: #F4F5FA; */
    background-color: #FFFFFF;
    padding: 1px 10px;
    border-radius: 6px;
}


.user__statistics__search__bar {
    /* background-image: url('../svg-store/userstatistics/search-bar.png'); */
    /* background-position: 10px 10px; */
    /* background-repeat: no-repeat; */
    width: 100%;
    font-size: 1rem;
    /* padding: 5px 8px; */
    border-radius: 5px;
    outline: none;
    border: none;
}

.user__statistics__search__bar::placeholder {
    font-size: 1rem;
}

.user__statistics__userslist {
    /* border: 2px solid black; */
    font-family: 'Open Sans';
    font-style: normal;

    /* display: flex; */
    /* flex-direction: column; */
    /* gap: 1rem; */
}

.user__statistics__userslist--box {
    width: 100%;
    height: 100%;
    /* background: #FFFFFF; */
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    /* border: 2px solid violet; */
    border-radius: 8px;
    height: calc(100% - (28px + 1rem));

    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
}

.user__statistics__userslist__heading__box {
    /* border: 2px solid red; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding: 0.6rem 1.2rem 0.6rem 2rem;
    color: #333333;
    height: 5vh;
}

.user__statistics__userslist__contents {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* font-size: 1rem; */
    padding: 1rem 0.7rem 1rem 2rem;
    color: #333333;
    /* border: 2px solid black; */
}

.user_statistics__userlist--color {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FFFFFF !important;
    color: #001944 !important;
}


.user__statistics__userslist--leftelement {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    column-gap: 3vw;
}


.user__stat__leftelem__span1 {
    /* border: 2px solid black; */
    word-wrap: break-word;
    width: 18vw;
    line-height: 1.3rem;
}

.user__stat__leftelem__span2 {
    /* border: 2px solid black; */
    word-wrap: break-word;
    width: 12vw;
    line-height: 1.3rem;
    display: flex;
    gap: 0.5rem;
}

.user__stat__leftelem__span2--cursor{
    cursor: pointer;
}

.user__statistics__userslist--rightelement {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vw;
    width: 28vw;
}

.user__statistics__userlist__total__queries {
    /* border: 2px solid black; */
    width: 10vw;
    /* width: 10rem; */
}


.user__statistics__userslist__rightelement--anotherelement {
    /* border: 2px solid purple; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2vw;
}


.user__statistics__userslist__anotherelement--element {
    /* border: 2px solid pink; */
    width: 5vw;
}

.user__acess_div{
    /* border: 2px solid red; */
    display: flex;
    cursor: pointer;
    /* width: 60%; */
}

.user__statistics__userslist__anotherelement--element>button {
    border: none;
    max-width: 100%;
    /* background: transparent; */
    display: block;
    /* align-items: flex-start;
    justify-content: flex-start; */
    cursor: pointer;
}

.user__statistics__userslist__anotherelement--element>button>img {
    /* border: 2px solid red; */
    /* display: flex;
    align-items: flex-start; */
    max-width: 100%;
}

.user__statistics__userslist__rightelement--anotherelement>button {
    border: none;
    background: transparent;
    display: block;
    cursor: pointer;
}


.user__statistics__userslist__content__box {
    /* 100% - (heading_height + padding_botton) */
    height: calc(100% - (5vh + 2rem));
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 0.9rem !important;
    color: #828282;
}

.user__statistics__userslist__content__innerbox {
    height: 100%;
    overflow-y: auto;
    /* display: flex;
    flex-direction: column;
    gap: 2rem; */
}

.user__statistics__userslist__content__innerbox::-webkit-scrollbar {
    width: 0.4rem;
}

.user__statistics__userslist__content__innerbox::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.20);
    border-radius: 4px;
}




/* Element-3 */
.userinqueries__filter__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid black; */
    height: 5vh;
}

.userinqueries__filter__box__leftelem {
    height: 100%;
    display: flex;
    column-gap: 2.5vw;
}

.userinquires__filter {
    height: 100%;
}

.userstats__leftelem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 6px 8px 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.userstats__cancel__div{
    /* margin-right: 0.5rem; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userinquires__filter__button {
    height: 100%;
    display: flex;
    border: 2px solid red;
    flex-direction: row;
    align-items: center;
    column-gap: 0.8vw;
    border: none;
    /* background: #FFFFFF;
    border-radius: 8px; */
    padding: 0px 1vw;
    cursor: pointer;
    position: relative;
}

.userinquires__filter__badge {
    position: relative;
    display: inline-block;
    color: #828282;
    font-size: 0.9rem;
    font-family: 'Open Sans';
    font-style: normal;
}

.userinquires__filter__badge .badge {
    position: absolute;
    top: -12px;
    right: -20px;
    /* padding: 5px 10px; */
    padding: 5px 5px;
    border-radius: 50%;
    background-color: #F47C42;
}


.filter--color {
    color: #333333;
}

.userinqueries__filter__number__box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.userinqueries__filter__left__elem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.userinqueries__filter__number {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.userinqueries__showing--color {
    color: #333333;
    font-size: 0.9rem;
}

.userinqueries--count {
    font-weight: bold;
    font-size: 0.9rem;
}

.userinqueries--count--blueColor{
    color: #00B4D8;
}

.filter__box__dropdown1 {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 0.7rem 0.5rem;
    margin-top: 0.7rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    position: absolute;
    z-index: 2;
}

.filter__box__dropdown--None {
    display: none;
}

.filter__box__dropdown__elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    cursor: pointer;
    padding: 0.2rem 0.6rem;

    position: relative;
}


.filter__box__dropdown__elem:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #fdfdfd;
}

.filter__box__dropdown__elem__span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* text-align: center; */
    color: #333333;
}

.filter__box__content {
    position: absolute;
    left: 300px;
    top: 205px;
    z-index: 2;
}

.filter__date__box {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    padding: 1.5rem 0.6rem;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.filter__date__elem1 {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.filter__date__child__box1 {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8rem;
    /* padding: 6px 8px; */
    /* padding: 6px 8px 6px 0px; */
    cursor: pointer;
}

.filter__date__child__box1:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #fdfdfd;
}

.filter__date__font__heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
}

.filter__date__font {
    /* border: 2px solid black; */
    width: 100%;
    text-align: left;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
    padding: 7px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
}

.filter__date__horizontal__line {
    border: 1px solid #E0E0E0;
}

.filter__date__elem2 {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.filter__date__elem2__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
}

.filter__date__specific__date {
    background: #FFFFFF;
    /* border: 1px solid #D0D5DD; */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 4px 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter__date__specific__date__elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.filter__date__specific__date__elem__span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.filter__date__button__box {
    display: flex;
}


.example {
    /* margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px; */
    /* border: 2px solid red; */
    padding: 5px 0px 20px 5px;
}

.ant-switch-handle{
    top: 3.5px !important;
}