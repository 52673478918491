.userchat__main {
    /* border: 2px solid red; */
    /* remaining height = page_height - (navbar_height + padding) */
    height: calc(100% - (39px + 2rem));
    box-sizing: border-box;
}

.userchat__main--box {
    /* border: 2px solid black; */
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans';
    font-style: normal;
    row-gap: 5vh;
}

/* Element-1 */
.userchat__userdetail__box {
    /* border: 2px solid blue; */
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1vw;
    height: 9vh;
    font-size: 1rem;
}


.userchat__userdetail__element1 {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    column-gap: 2vw;
}

.userchat__userdetails {
    /* border: 2px solid black; */
    display: flex;
    column-gap: 2vw;
}

.userchat__userdetails--elem {
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5vw;
    font-family: 'Open Sans';
    font-style: normal;
    color: #828282;
}

.userchat__username {
    /* border: 2px solid rgb(157, 13, 201); */
    word-break: break-all;
    color: #001944;
    /* font-weight: bold; */
}

.userchat__userid {
    /* border: 2px solid red; */
    word-break: break-all;
    color: #001944;
    /* font-weight: bold; */
}



.userchat__userdetail__element2 {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vw;
    height: 100%;
}

.userchat__userdetail__element2>span {
    /* font-weight: bold; */
}

.userchat__view__questions {
    /* width: 2.5vw; */
    /* height: 2.5vh; */
    height: 6vh;
    border: none;
    background: transparent;
    cursor: pointer;
}

.userchat__view__questions>img {
    height: 100%;
}


/* Element-2 */
.userchat__filter__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid black; */
    height: 5vh;
}

.userchat__filter__box__leftelem {
    height: 100%;
    display: flex;
    column-gap: 2.5vw;
}

.userinquires__filter {
    height: 100%;
}

.userinquires__filter__button {
    height: 100%;
    display: flex;
    border: 2px solid red;
    flex-direction: row;
    align-items: center;
    column-gap: 0.8vw;
    border: none;
    /* background: #FFFFFF; */
    border-radius: 8px;
    padding: 0px 1vw;
    cursor: pointer;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* padding: 2vh 2vh; */
}

.userinquires__filter__badge {
    position: relative;
    display: inline-block;
    color: #828282;
    font-size: 0.9rem;
    font-family: 'Open Sans';
    font-style: normal;
}

.userinquires__filter__badge .badge {
    position: absolute;
    top: -12px;
    right: -20px;
    /* padding: 5px 10px; */
    padding: 5px 5px;
    border-radius: 50%;
    background-color: #F47C42;
}

.filter--color {
    color: #333333;
}

.userinqueries__showing--color {
    color: #333333;
    font-size: 0.9rem;
}

.userinqueries--count {
    font-weight: bold;
}

/* Element-3 */
.userchat__userchat__box {
    /* border: 2px solid black; */
    /* 100% - (userDetailBoxHeight + row_gaph + filterHeight + rowGaph) */
    /* height: calc(100% - (9vh + 5vh + 5vh + 5vh)); */
    height: calc(100% - (9vh + 5vh));

    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
}

.userchat__userchat__box--main {
    height: 100%;
}

.userchat__userchat__heading {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2vw;
    /* padding: 0vh 2.5vw; */
    padding-left: 2.5vw;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    color: #333333;
    height: 15%;
}

.userchat__userchat__elem1 {
    /* border: 2px solid black; */
    width: 10%;
    word-break: break-all;
}



.userchat__userchat__chat__box {
    /* border: 2px solid red; */
    height: 85%;
}

.userchat__userchat__chat__box--scroll{
    /* border: 2px solid red; */
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    /* padding-left: 2.5vw; */
}

.userchat__userchat__chat__box--scroll::-webkit-scrollbar {
    width: 0.4rem;
}

.userchat__userchat__chat__box--scroll::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.20);
    border-radius: 4px;
}

.userchat__userchat__chats {
    display: flex;
    align-items: center;
    column-gap: 2vw;
    border-bottom: 1px solid #E0E0E0;
    
    padding-left: 2.5vw;
    /* padding: 0.8rem 0rem; */
    padding-top: 0.9rem;
    padding-bottom: 1rem;
    font-size: 0.9rem;
    box-sizing: border-box;
}

.user_id1--color {
    color: #828282;
    font-size: 0.9rem;
}

.userchat__userchat__elem2 {
    /* border: 2px solid red; */
    width: calc(90% - 2.5rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userchat__userchat__elem2--question {
    /* border: 2px solid black; */
    /* width: 100%; */
    width: 96%;
    color: #001944;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.expand__chat__btn1{
    /* border: 2px solid black; */
    width: 2.5%;
}

.expand__chat__btn1>img{
    width: 100%;
}

.userchat__userchat__elem2--question>span{
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #333333;
    font-weight: bold;
}

.userchat__userchat__ques--time{
    color: #828282;
}

.userchat__userchat__ques {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Box Expand CSS -----------------*/
.userchat__userchat__elem2__expandedQuestion {
    /* border: 2px solid red; */
    width: calc(90% - 2.5rem);
    display: flex;
    flex-direction: column;
    row-gap: 0.9rem;
}

.userchat__userchat__elem2__expandedQuestion__main{
    border: 1.2px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    max-height: 50vh;
    overflow-y: auto;
    padding: 1rem;
    white-space: pre-line;
    word-wrap: break-word;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px; */
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
}

.userchat__userchat__elem2__expandedQuestion__main::-webkit-scrollbar {
    width: 0.4rem;
}

.userchat__userchat__elem2__expandedQuestion__main::-webkit-scrollbar-thumb {
    background: rgba(15, 14, 14, 0.4);
    border-radius: 4px;
}

.userchat__question{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}



.userchat--ques{
    line-height: 1.5rem;
    color: #001944;
}

.userchat__answer{
    color: #828282;
    line-height: 1.8rem;
}

.userchat__answer--link__heading{
    font-size: 15px;
    font-weight: bold;
}





.userchat__feedback__box{
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.8rem;
}

.userchat__feedback__elem1{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.userchat__feedback__elem1__span{
    font-size: 1rem;
    font-weight: bold;
}

.userchat__feedback__elem2__span1{
    font-size: 1rem;
    font-weight: bold;
}

.userchat__datetime>span{
    font-weight: bold;
}