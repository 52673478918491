
:root {
  --body__background: #183364;
  --sector__box--background: #F4F5FA;
  --navbar__background: #FFFFFF;
  --technical__advisor--background: #F4F5FA;
  --sidebar__background: #183364;
  --adminpage__background: #F4F5FA;
}

body {
  background-color: var(--body__background);
}

/* Overiding button property */
.no__button{
  border: none;
  background: transparent;
  cursor: pointer;
}