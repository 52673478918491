
.delete__message__container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(30, 30, 30, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.delete__message__background {
    padding: 1.4rem 1.2rem 1.4rem 1.4rem;
    min-height: 45%;
    min-width: 30%;
    background: linear-gradient(293.59deg, #E5F3FD -25.19%, #F9FBFB 69.6%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
}

.delete__message__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.close__delete__modal {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 2rem;
}

.delete__text__msg {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
    color: #0066B2;
}

.delete__message__info {
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.6rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #0E0E0F;
}

.delete__message__info>span>span {
    font-weight: bold;
    margin-left: 0.4rem;
    font-size: 1rem;
}

.delete__message__buttons {
    display: flex;
    column-gap: 1.5rem;
    margin-top: 1rem;
}

.delete__message__buttons button {
    min-width: 7rem;
    padding: 0.2rem 0rem;
    cursor: pointer;

    background: #FFFFFF;
    border: 2px solid #0066B2;
    border-radius: 40px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 4rem;
}

.delete__message__buttons button:first-child {
    color: #0066B2;
}

.delete__message__buttons button:first-child:hover {
    background: #f3f0fe;

}

.delete__message__buttons button:nth-child(2) {
    background: #0066B2;
    color: #FFFFFF;
    border: none;
}

.delete__message__buttons button:nth-child(2):hover {
    background-color: #0479d0;
}