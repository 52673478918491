.nav__main__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Open Sans';
    font-style: normal;
    background: var(--navbar__background);
    padding: 0.8rem;
    /* height: 5.5vh; */
    height: 39px;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nav__left__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;
    height: 100%;
}

.nav__togglebar {
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 0.4rem;
    width: 2.4rem;
    align-items: center;
}

.nav__togglebar button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    padding: 0.4rem;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.nav__valiance__logo {
    width: 3.5rem;
    height: 100%;
}

.nav__valiance__logo img {
    height: 100%;
    width: 100%;
}

.nav__vertical__line {
    border: 1px solid #E0E0E0;
    height: 100%;
}

.nav__client__name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 100%;
}

.nav__client__name--title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0E0E0F;

}

.nav__client__name .nav__client__name--subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2D3443;
}


.nav__right__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    height: 100%;
}

.nav__language__selection {
    height: 100%;
    position: relative;
}

.nav__language__selection>button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    height: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.nav__language__selection>button>span {
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #0066B2;
}

.nav__language__dropdown_main__box--None {
    display: none;
}

.nav__language__dropdown_main__box {
    border-radius: 4px;
    position: absolute;
    top: 3.7rem;
    right: 0.5rem;
    background-color: #F4F5FA;
    z-index: 2;
}

.nav__language__dropdown_box {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 8px;
    width: 18rem;
    padding: 0.5rem 0.5rem 0.5rem 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.nav__language__dropdown--upperbox {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav__language__dropdown--upperbox>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #0E0E0F;
}

.nav__language__dropdown--apiselection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.nav__language__dropdown--apiselection>img {
    cursor: pointer;
}

.nav__language__dropdown--apiselection>a {
    text-decoration: none;
    display: flex;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4E4E51;
}


.nav__language__dropdown--selectedapi {
    font-weight: 700;
    color: #0066B2;
}

.nav__language--horizontalline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}


.nav__language__dropdown--lowerbox {
    display: flex;
    flex-direction: column;
}

.nav__language__dropdown--lowerbox>span {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 18px;
    color: #4E4E51;
}

.nav__language__dropdown--languagesbox {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    max-height: 12rem;
    overflow-y: auto;
}

.nav__language__dropdown--languagesbox::-webkit-scrollbar {
    width: 7px;
}

.nav__language__dropdown--languagesbox::-webkit-scrollbar-thumb {
    background: rgba(20, 19, 19, 0.16);
    border-radius: 4px;
}

.nav__language__dropdown--languagesbox>a {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 2.2rem;
    padding: 0.5rem;
    box-sizing: border-box;
}

.nav__language__dropdown--languagesbox>a:hover {
    background: #EEF2FE;
}

.nav__language__dropdown--languagesbox>a>span {

    font-family: 'Work Sans';
    font-style: normal;
    font-size: 0.9rem;
    line-height: 20px;
    color: #0E0E0F;
    font-weight: 400;

}

.nav__language__dropdown--activelanguagesbox {
    color: #0E0E0F !important;
    font-weight: 700 !important;
}

.nav__language__dropdown--activeanchortag {
    background: #EEF2FE;
}


.nav__user {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.9rem;
}

.nav__user img {
    cursor: pointer;
}

.nav__user__image {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav__user--img {
    height: 98%;
    transition: 1s ease;
}

.nav__user--img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}



.nav__user__img__dropdown__main {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 10rem;
    top: 3.7rem;
    right: 0.3rem;
    padding: 0.5rem 0rem;
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.nav__user__img__dropdown__box {
    display: flex;
    flex-direction: column;
}

.nav__user__img__dropdown__box>button {
    background: transparent;
    outline: none;
    border: none;
    display: block;
    text-align: left;
    padding: 0.6rem 4rem 0.6rem 1rem;
    color: #0E0E0F;
    cursor: pointer;
}

.nav__user__img__dropdown__box>button:hover {
    color: blue !important;
    background: #EEF2FE;
}


.nav__user__img__dropdown__box>button>span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}



.nav__user__name {
    font-size: 0.9rem;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: #0E0E0F;
}

.nav__user__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nav__user__name__tooltip{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}