
.query__metrics__list__container {
    max-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 20px;
}

.query__metrics__list__box {
    width: 50%;
    border: 1.2px solid rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    box-sizing: border-box;
}

.query__metrics__list__box__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 83%;
    border-radius: inherit;
}

.query__metrics__list__box__content::-webkit-scrollbar {
    width: 8px;
}


.query__metrics__list__box__content::-webkit-scrollbar-thumb {
    background: rgba(52, 51, 51, 0.5);
    border-radius: 10px;
}

.query__metrics__list__box__content::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 51, 51, 0.7);
}

.query__metrics__list__heading {
    padding: 8px 10px 2px 10px;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.2rem;
    height: 15%;
    box-sizing: border-box;
}

.query__metrics__list__data {
    padding: 10px;
    font-size: 0.9rem;
    line-height: 1.5rem;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.2);
}

.query__metrics__list__data--color {
    background: white;
}


